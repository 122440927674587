<template>
  <div class="pa-4">
    <v-card v-if="isStudent" flat>
      <Table
        :idClass="idClass"
        :isInitData="initData"
        :loading="loading"
        @loading="value => (loading = value)"
        @changeInitData="newValue => (initData = newValue)"
      />
    </v-card>
    <v-card v-else flat>
      <Header
        :idClass="idClass"
        :idSchoolYear="idSchoolYear"
        @setIdClass="newValue => (idClass = newValue)"
        @setIdSchoolYear="newValue => (idSchoolYear = newValue)"
        @getSchedule="newValue => (initData = newValue)"
      />
      <Table
        :idClass="idClass"
        :idSchoolYear="idSchoolYear"
        :isInitData="initData"
        :loading="loading"
        isManageSchedule
        @loading="value => (loading = value)"
        @changeInitData="newValue => (initData = newValue)"
      />
    </v-card>
    <v-overlay :value="loading" :z-index="6">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </div>
</template>

<script>
import store from "@/store";
import i18n from "@/i18n";

export default {
  metaInfo: {
    title: i18n.t("routes.schedule"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    Header: () => import("./Header"),
    Table: () => import("./Table")
  },
  data() {
    return {
      isStudent: store.getters.g_role_type === "STUDENT",
      initData: false,
      idClass: null,
      idSchoolYear: null,
      loading: false
    };
  }
};
</script>
